<script lang="ts">
  let class_list = '';
  export {class_list as class};

  export let free = false;

  $: color = free ? '#77FC4E' : '#fcd34d';
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class={class_list}>
  <g id="g2155" transform="translate(0.55434799,-2.52452087)">
    <ellipse
      style={`fill:${color};fill-opacity:1;stroke:${color};stroke-width:0.975425;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
      id="path563-1-5-3-4-38-8-8-9-4-8-8-0-5-4-7-4-5-6-1-1-8"
      cx="11.445652"
      cy="12.524521"
      rx="7.4654789"
      ry="7.5843387"
    />
    <path
      transform="matrix(0.23492909,-4.0267461e-4,2.8877858e-4,0.2210633,-28.791632,-5.3835839)"
      style="fill:none;fill-opacity:1;stroke:#005082;stroke-width:4.06455;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    />
    <path
      transform="matrix(0.23492909,-4.0267461e-4,2.8877858e-4,0.2210633,-28.5017,-5.5415628)"
      d="m 158.81966,101.72711 0,-19.36492 0,-19.364916 16.77051,9.682458 16.77051,9.682458 -16.77051,9.682459 z"
      style="fill:none;fill-opacity:1;stroke:#005082;stroke-width:4.06455;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    />
    <path
      transform="matrix(0.23492909,-4.0267461e-4,2.8877858e-4,0.2210633,-28.810224,-5.7125551)"
      d="m 158.81966,101.72711 0,-19.36492 0,-19.364916 16.77051,9.682458 16.77051,9.682458 -16.77051,9.682459 z"
      style="fill:none;fill-opacity:1;stroke:#3b82f6;stroke-width:4.06455;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    />
    <g
      aria-label="Ξ"
      transform="matrix(1.0259831,0.01245646,-0.00884958,0.97456748,0,0)"
      style="font-style:normal;font-weight:normal;font-size:0.807913px;line-height:0%;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#bc6d0b;fill-opacity:1;stroke:none;stroke-width:0.0673257px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
    >
      <path
        d="M 7.4794651,10.114165 H 2.2336357 V 9.2755946 H 7.4794651 Z M 7.2317057,12.896694 H 2.4813951 v -0.83857 H 7.2317057 Z M 7.4794651,16.37009 H 2.2336357 v -0.83857 h 5.2458294 z"
        style="font-size:9.75791px;line-height:1.25;fill:#bc6d0b;fill-opacity:1;stroke-width:0.0673257px"
        id="path2230"
      />
    </g>
    <g
      aria-label="Ξ"
      transform="matrix(1.0259831,0.01245646,-0.00884958,0.97456748,0,0)"
      style="font-style:normal;font-weight:normal;font-size:0.807913px;line-height:0%;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#f59e0b;fill-opacity:1;stroke:none;stroke-width:0.0673257px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
    >
      <path
        d="M 7.4776355,9.8519522 H 2.2318061 V 9.0133818 H 7.4776355 Z M 7.2298761,12.634481 H 2.4795655 v -0.83857 h 4.7503106 z m 0.2477594,3.473397 H 2.2318061 v -0.838571 h 5.2458294 z"
        style="font-size:9.75791px;line-height:1.25;fill:#f59e0b;fill-opacity:1;stroke-width:0.0673257px"
        id="path2227"
      />
    </g>
    <ellipse
      style={`fill:none;stroke:${color};stroke-width:0.918875;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
      id="path563-1-5-3-4-38-8-8-9-4-8-8-0-5-4-7-5-0-7-8-6"
      cx="11.445652"
      cy="12.524521"
      rx="9.475563"
      ry="9.5014782"
    />
  </g>
</svg>
